import React, { Suspense, lazy } from 'react'
import { Routes, Route, Navigate } from 'react-router-dom'
import ProtectedRoutes from 'layouts/ProtectedRoutes';
import MainLayout from './layouts/MainLayout';
const Login = lazy(() => import('./pages/login'))
const Checkout = lazy(() => import('./pages/checkout'))
const Customize = lazy(() => import('./pages/customize'))
const NotFound = lazy(() => import('./pages/not-found'))

function App() {

    return (
        <Suspense>
            <Routes>
                <Route element={<MainLayout />}>
                    <Route index element={<Navigate to="/login" replace />} />
                    <Route path='/login' element={<Login />}></Route>
                    <Route element={<ProtectedRoutes />}>
                        <Route path='/customize/:step' element={<Customize />}></Route>
                        <Route path='/checkout/:slug' element={<Checkout />}></Route>
                    </Route>
                </Route>
                <Route path='*' element={<NotFound />} />
            </Routes>
        </Suspense>
    );
}

export default App;
