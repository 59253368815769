export const CONSTANTS = {
    DEALER_TOKEN: 'V_DEALER_TOKEN',
    CUSTOMER_TOKEN: 'V_CUSTOMER_TOKEN',
    DEALER_ID: 'V_DEALER_ID',
    CURRENT_CUSTOMIZATION: 'V_CURRENT_CUSTOMIZATION_ID',
    CURRENT_PRODUCT: 'V_CURRENT_PRODUCT_ID',
    ORDER_ID: 'V_ORDER_ID',
    MOBILE: 'TEMP_MOBILE',
    JAR_LIMITS: 99,
}
