import { useEffect } from 'react';
import { Outlet } from 'react-router-dom'
import { useLocation } from 'react-router-dom';
import { getLocalStorage } from "helpers/HelperFunctions";
import { CONSTANTS } from 'helpers/AppConstants'
import { useNavigate } from 'react-router-dom';
import useMediaQuery from '@mui/material/useMediaQuery';
import Header from './utility/Header';
import Footer from './utility/Footer';

function MainLayout() {
    const authToken = getLocalStorage(CONSTANTS.DEALER_TOKEN)
    const navigate = useNavigate();
    const location = useLocation();
    const matches = useMediaQuery('(max-width:1024px)');

    // Don't allow login page ...already signed in
    useEffect(() => {
        if (authToken && location?.pathname === "/login") {
            navigate(`/customize/select-product`);
        }
    }, [authToken, location?.pathname])

    return (
        <section className={location?.pathname === "/login" ? "mm-layout" : "m-layout"}>
            {
                location?.pathname === "/home" ||
                    location?.pathname === "/my-profile" ||
                    location?.pathname === "/my-customizations" ||
                    location?.pathname === "/my-orders" ? <Header /> : null
            }
            {location?.pathname === "/login" && matches === true ? <Header /> : null}
            <Outlet />
            {
                (() => {
                    if (location?.pathname === "/home") {
                        return <Footer />
                    }
                    else {
                        if (!matches) {
                            return <Footer />
                        }
                        else {
                            return null
                        }
                    }
                })()
            }
        </section>
    );
}

export default MainLayout;
